<template>
  <div class="con" v-if="!$store.state.pcshow">
      <img src="../../assets/img/index/yilianGl15.png" alt="" />
      <div class="btngroup clearfix">
        <!-- <router-link to="/zhongkeYC" :class="$route.path === '/zhongkeYC'? 'active': ''">
          <div class="companyIntro">
            <p>中科医创</p>
          </div>
        </router-link> -->
        <router-link to="/zhongxinGH"  :class="$route.path === '/zhongxinGH'? 'active': ''">
          <div class="structure">中鑫广汇</div>
        </router-link>
        <router-link to="/yilianGl"  :class="$route.path === '/yilianGl'? 'active': ''">
          <div class="structure">亿联管理</div>
        </router-link>
        <router-link to="/chanyeYQ"  :class="$route.path === '/chanyeYQ'? 'active': ''">
          <div class="structure">产业园区</div>
        </router-link>
      </div>
      <div class="hprofile">
        <h3 class="conh3">COMPANY PROFILE</h3>
        <h4 class="conh4">公司简介</h4>
        <img src="../../assets/img/index/yilianGl17.png" alt="">
        <h5>北京亿联祥和企业管理有限公司</h5>
        <p>北京亿联祥和独角兽企业管理有限公司（下简称公司）是大健康领域国内一流 的产业园区运营服务商，致力于通过数字化服务创新，导入资源、产业、投资、 技术、运营和人才等核心要素，为医药产业升级提供“科技企业孵化、技术和 产品贸易、成果转化、产业链整合”等企业服务。</p>
        <p>公司致力于协助在孵企业的业务发展，在孵企业业务收入从2019年2514.15万元 迅速增长为 2020 年 6525.41 万元，在孵企业发展同时，孵化器企业也将随之壮 大。同时企业与北京兴谷发展公司签订了财政奖励协议，未来在完成一定数额的 税收之后，也会得到财政上的支持与奖励。公司未来能够更有效，更优质的孵化 更多高科技创新医疗企业。</p>
        <img src="../../assets/img/index/yilianGl18.png" alt="" class="h5imgtwo">
        <h5>天津亿联体孵化器管理有限公司</h5>
        <p>天津亿联体孵化器管理有限公司（海教园）成立于2020年，结合海教园 高等学府优势，整合健康行业企业用人需求，聚焦大健康领域人才教育 与定向培养，以当地学府作为依托大力开发合作办学项目并与学校开展 课题研究，初步形成企教联盟产业运营生态，确立了“四个”核心（医 疗服务、职业教育、医生IP、产品研发）的发展模式。</p>
        <p>该项目总体投资1300万元人民币，预计达产后年产值2亿元人民币。</p>
      </div>
      <div class="yj">
        <h4><span></span>园区布局</h4>
        <img src="../../assets/img/index/yilianGl19.png" alt="">
      </div>
  </div>
  <!-- 产业园区 -->
  <div class="main" v-else>
    <img src="../../assets/img/index/imgCYYQ(1).png" alt="" />
    <div class="hatchTotalNav">
      <div class="hatchleft">
        <div class="hatchleftContent">
          <p>INCUBATION ENTERPRISE</p>
          <p>孵化企业</p>
        </div>
      </div>
      <div class="hatchNavRight">
        <div class="hatchNavRightContent">
          <!-- <router-link to="/zhongkeYC">
            <div>中科医创</div>
          </router-link> -->
          <router-link to="/zhongxinGH">
            <div>中鑫广汇</div>
          </router-link>
          <router-link to="/yilianGl">
            <div>亿联体事业部</div>
          </router-link>
          <router-link to="/chanyeYQ">
            <div
              :class="
                $route.path === '/chanyeYQ' ? 'companyActive' : 'companyDefault'
              "
            >
              <p>产业园区</p>
              <div class="bluebottom"></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="contentMain">
      <!-- 业务内容标题 -->
      <div class="croBusiness">
        <p>园区介绍</p>
        <div class="blueBttom"></div>
      </div>
      <!-- 公司介绍 -->
      <div class="companyIntro">
        <div class="companyIntroContent">
          <div class="companyIntroLeft">
            <h4>北京亿联祥和企业管理有限公司</h4>
            <p>
              北京亿联祥和独角兽企业管理有限公司（下简称公司）是大健康领域国内一流
              的产业园区运营服务商，致力于通过数字化服务创新，导入资源、产业、投资、
              技术、运营和人才等核心要素，为医药产业升级提供“科技企业孵化、技术和
              产品贸易、成果转化、产业链整合”等企业服务。
            </p>
            <p>
              公司致力于协助在孵企业的业务发展，在孵企业业务收入从2019年2514.15万元
              迅速增长为 2020 年 6525.41
              万元，在孵企业发展同时，孵化器企业也将随之壮
              大。同时企业与北京兴谷发展公司签订了财政奖励协议，未来在完成一定数额的
              税收之后，也会得到财政上的支持与奖励。公司未来能够更有效，更优质的孵化
              更多高科技创新医疗企业。
            </p>
          </div>
          <div class="companyIntroRight">
            <img src="../../assets/img/index/imgCYYQ(2).png" alt="" />
          </div>
        </div>
      </div>
      <!-- 公司介绍2 -->
      <div class="companyIntro2">
        <div class="companyIntro2Content">
          <div class="companyIntro2Left">
            <img src="../../assets/img/index/imgCYYQ(3).png" alt="" />
          </div>
          <div class="companyIntro2Right">
            <h4>天津亿联体孵化器管理有限公司</h4>
            <p>
              天津亿联体孵化器管理有限公司（海教园）成立于2020年，结合海教园
              高等学府优势，整合健康行业企业用人需求，聚焦大健康领域人才教育
              与定向培养，以当地学府作为依托大力开发合作办学项目并与学校开展
              课题研究，初步形成企教联盟产业运营生态，确立了“四个”核心（医
              疗服务、职业教育、医生IP、产品研发）的发展模式。
            </p>
            <p>该项目总体投资1300万元人民币，预计达产后年产值2亿元人民币。</p>
          </div>
        </div>
      </div>
      <!-- 园区布局 -->
      <div class="gardenMap">
        <div class="gardenTop">
          <div class="gardenTitle">园区布局</div>
          <div class="blueBttom"></div>
        </div>
        <div class="gardenBottom">
          <img src="../../assets/img/index/imgCYYQ(4).png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) {
      .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }

      .btngroup{
        width: 6.8rem;
        padding-left:0.35rem;
        padding-right:0.35rem; 
        a{
          display: block;
          width: 2rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.4rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        }
        a.active{
          background: #0085D0;
          color: #ffffff;
        }
        a:nth-child(3){
          margin-right:0;
        }
      }
      .hprofile{
        display block
        width calc( 100% - 0.36rem )
        padding-left 0.36rem
        background #F5F5F5
        padding-bottom 0.2rem;
        background: #fff;
        margin-top: 0.4rem;

        img{
          display: block
          width: 5rem
          height: 3rem
          margin-bottom: 0.32rem
        }
        
        h5{
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          // padding-top: 0.24rem;
          padding-bottom:0.1rem;
        }
        p{
          width: 6.86rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
          margin-bottom:0.1rem;
          word-break:break-all;
        }
      }
      .h5imgtwo{
        margin-top: 0.3rem;
      }
    }
    .conh3{
      padding-top: 0.4rem
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .conh4{
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding 0.15rem 0 0.24rem 0
    }
    .yj{
      display: block;
      width: calc( 100% - 0.35rem );
      background: #ffffff;
      padding-left:0.35rem;
      // padding-bottom:0.05rem;
      border-top: 0.4rem solid #f5f5f5;
      border-bottom: 0.4rem solid #f5f5f5;

      >h4{
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 0.39rem;
        padding-top:0.24rem;
        padding-bottom:0.32rem;
        span{
          display: inline-block;
          width: 0.03rem;
          height: 0.23rem;
          background: #0085D0;
          margin-right:0.07rem;
        }
      }
      img{
        display: block;
        width: 6.8rem;
        height: 5.01rem;
        padding-bottom:0.47rem;

      }
    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      font-family: Source Han Sans CN;
      color: #333333;

      >img {
        margin-top: 100px;
        width: 100%;
      }

      .hatchTotalNav {
        position: relative;
        display: flex;
        margin: 0 auto;
        width: 1280px;

        .hatchleft {
          position: absolute;

          // top: -62px;
          // width: 645px;
          .hatchleftContent {
            width: 800px;
            height: 162px;
            background: linear-gradient(0deg, #0085D0, #09B7CF);
            position: absolute;
            top: -63px;
            left: -471px;

            p:nth-child(1) {
              height: 19px;
              padding: 53px 0 15px 456px;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }

            p:nth-child(2) {
              width: 118px;
              height: 29px;
              margin-left: 456px;
              font-size: 29px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }
          }
        }

        .hatchNavRight {
          width: 1280px;
          margin: 0 auto;

          // 右边导航
          .hatchNavRightContent {
            flex: 1;
            color: #666666;
            box-sizing: border-box;
            height: 100px;
            background: #FFFFFF;
            border-right: 0px solid #8df;
            box-shadow: 0px 2px 6px 0px rgba(209, 209, 209, 0.65);
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            top: -1px;
            left: 329px;
            padding-right: 240px;

            // width: 1280px;

            // margin-right: auto;
            a:hover {
              div {
                color: #0085D0;
              }

              div:nth-child(2) {
              }
            }

            a:nth-child(4) {
              position: relative;

              .bluebottom {
                display: none;
                position: absolute;
                top: 60px;
                left: -5px;
                width: 72px;
                height: 2px;
                background: #0085D0;
              }
            }

            .companyActive {
              color: #0085D0;

              div:nth-child(2) {
                display: block;
              }
            }

            .companyDefault {
            }
          }
        }
      }

      .contentMain {
        .croBusiness {
          width: 1280px;
          margin: 0 auto;
          margin-top: 120px;

          p {
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            line-height: 39px;
            margin-bottom: 41px;
          }

          .blueBttom {
            text-align: center;
            margin: 41px auto;
            width: 42px;
            border-bottom: 5px solid #0085D0;
          }
        }

        .companyIntro {
          color: #333333;
          background: #F8F9FA;
          padding: 140px 0 150px;
          margin-bottom: 120px;

          .companyIntroContent {
            display: flex;
            justify-content: space-between;
            width: 1280px;
            margin: 0 auto;

            .companyIntroLeft {
              h4 {
                height: 38px;
                font-size: 40px;
                font-weight: 500;
                line-height: 39px;
                margin-bottom: 45px;
              }

              p {
                padding-right: 45px;
                height: 137px;
                font-size: 20px;
                font-weight: 400;
                line-height: 39px;
                margin-bottom: 24px;
              }
            }

            .companyIntroRight {
              width: 548px;
              height: 514px;
            }
          }
        }

        .companyIntro2 {
          // padding: 135px 319px 151px;
          padding: 135px 0 151px;
          background-color: #F8F9FA;
          margin-bottom: 120px;

          .companyIntro2Content {
            width: 1280px;
            margin: 0 auto;
            display: flex;

            .companyIntro2Left {
            }

            .companyIntro2Right {
              margin-left: 114px;

              h4 {
                margin: 0 0 45px 0;
                height: 39px;
                font-size: 40px;
                font-weight: 500;
              }

              p {
                height: 137px;
                font-size: 20px;
                font-weight: 400;
                color: #333333;
                line-height: 39px;
                margin-bottom: 55px;
              }
            }
          }
        }

        .gardenMap {
          width: 1280px;
          margin: 0 auto;
          // padding: 0 319px;
          padding: 0 0;
          margin-bottom: 120px;

          .gardenTop {
            text-align: center;

            .gardenTitle {
              height: 29px;
              font-size: 30px;
              font-weight: 500;
              line-height: 39px;
            }

            .blueBttom {
              text-align: center;
              margin: 41px auto;
              width: 42px;
              border-bottom: 5px solid #0085D0;
            }
          }
          .gardenBottom {
            margin 0 auto
            height 950px
            img {
            }
          }
        }
      }
    }
  }
</style>